<script setup lang="ts">
import { v4 as uuid } from 'uuid';
import { Chart, registerables } from 'chart.js';
import type { ChartData, ChartOptions, ChartTypeRegistry, InteractionItem, Plugin } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

import 'chartjs-adapter-date-fns'; // For time cartesian axis

type ChartPluginsEnum = 'chartjs-plugin-zoom' | 'chartjs-plugin-annotation';

const props = defineProps<{
  disabledButton?: boolean;
  type: keyof ChartTypeRegistry;
  data: ChartData;
  options?: ChartOptions;
  plugins?: ChartPluginsEnum[];
  resetZoom?: boolean;
  loading?: boolean;
  legend?: boolean;
}>();

const canvas = ref<HTMLCanvasElement | null>(null);
const isZoomed = ref(false);
const emit = defineEmits<{
  zoom: [startLabelIndex: number, endLabelIndex: number, isUnzoom?: boolean];
  'label-click': [value: { data: InteractionItem; label: string }];
  'bar-click': [value: number];
}>();

let chart: Chart;

const state = reactive<{
  id: string;
  defaultData: ChartData;
  defaultOptions: ChartOptions;
}>({
  id: uuid(),
  defaultData: {
    labels: ['default label 1', 'default label 2', 'default label 3'],
    datasets: [
      {
        label: 'dataset default',
        data: [1, 2, 3],
      },
    ],
  },
  defaultOptions: {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        pointStyle: false,
      },
      line: {
        borderWidth: 2,
        borderJoinStyle: 'round',
      },
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
          onZoom: (data) => {
            emit('zoom', data.chart.scales.x.min, data.chart.scales.x.max);
          },
          onZoomComplete: () => {
            isZoomed.value = true;
          },
        },
      },
      legend: {
        display: props.legend,
        position: 'bottom',
      },
    },

    onHover: function (event, chartElement) {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },

    onClick: (evt) => {
      const activePoints = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

      if (activePoints.length > 0) {
        emit('bar-click', activePoints[0].index);
      }
      if (activePoints.length) {
        const firstPoint = activePoints[0];
        const label = chart.data.labels[firstPoint.index]?.toString() || '';
        emit('label-click', { data: firstPoint, label });
      }
    },
  },
});

// Computed
const checkIfDataIsEmpty = computed(() => {
  let isEmpty = false;
  props.data.datasets.forEach((dataset) => {
    if (dataset.data.length === 0) isEmpty = true;
  });
  return isEmpty;
});

// Functions
const resetZoom = () => {
  chart.resetZoom();
  isZoomed.value = false;
  emit('zoom', 0, props.data.labels?.length - 1 || 0, true);
};

function generateData(): ChartData {
  const defaultData = state.defaultData as ChartData;
  return props.data ?? defaultData;
}

function generateOptions(): ChartOptions {
  return {
    ...state.defaultOptions,
    ...props.options,
    plugins: { ...state.defaultOptions.plugins, ...props.options?.plugins },
  };
}

const generatePlugins = (): Plugin[] => {
  const plugins: Plugin[] = [];

  if (props.plugins?.includes('chartjs-plugin-zoom')) plugins.push(zoomPlugin);
  if (props.plugins?.includes('chartjs-plugin-annotation')) plugins.push(annotationPlugin);

  return plugins;
};

const loadChart = () => {
  if (!canvas.value) return;
  const ctx = canvas.value.getContext('2d');

  Chart.register(...registerables);

  // Annotations must be imported via registerables
  if (props.plugins?.includes('chartjs-plugin-annotation')) Chart.register(annotationPlugin);

  chart = new Chart(ctx, {
    type: props.type,
    data: generateData(),
    options: generateOptions(),
    plugins: generatePlugins(),
  });
};

// Lifecycle
onMounted(() => {
  loadChart();
});

watch(
  () => props.data,
  () => {
    if (chart) {
      chart.data = generateData();
      chart.update();
    }
  },
);

watch(
  () => props.options,
  () => {
    chart.options = generateOptions();
    chart.update();
  },
);

watch(
  () => props.type,
  () => {
    chart.destroy();
    loadChart();
  },
);

watch(
  () => props.resetZoom,
  () => {
    if (props.resetZoom) {
      resetZoom();
    }
  },
);
</script>

<template>
  <div class="relative h-[50vh]">
    <div
      v-if="loading"
      class="absolute z-10 flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100 top-0 left-0 right-0 bottom-0"
    >
      <ui-loader />
      <p class="text-gray-500 text-sm mt-2">{{ $t('global.loading_data') }}</p>
    </div>
    <div
      v-else-if="checkIfDataIsEmpty"
      class="absolute z-10 flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100 top-0 left-0 right-0 bottom-0"
    >
      <p class="text-gray-500 text-sm">{{ $t('global.no_data') }}</p>
    </div>
    <div class="relative flex h-[50vh] flex-col justify-end w-full">
      <slot v-if="!disabledButton && props.plugins?.includes('chartjs-plugin-zoom')" name="button">
        <div class="flex items-center justify-end absolute top-2 right-0">
          <ui-button color="secondary" size="sm" :disabled="!isZoomed" @click="resetZoom">
            {{ $t('global.unzoom') }}
          </ui-button>
        </div>
      </slot>

      <canvas ref="canvas" class="w-[100%] h-[100%] -ml-6" />
    </div>
  </div>
</template>
